'use client';

import { useParams } from 'next/navigation';

import type { DomainWithLimitedSymbols } from 'src/shared/types';

export const useRestaurantDomain = () => {
    const { domain: restaurantDomain } = useParams();
    return restaurantDomain as DomainWithLimitedSymbols;
};
