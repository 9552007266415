'use client';

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MuiLink from '@mui/material/Link';
import Image from 'next/image';
import { NonEmptyStringSchema } from 'src/shared/types';
import { formatTimeStringHourMinute } from 'restaurant-v3/src/utils/formatTimeString';
import { getHref } from 'restaurant-v3/src/utils/navigation';
import { cssPxValues } from 'restaurant-v3/src/constants/cssPxValues';
import { footerId } from 'restaurant-v3/src/constants/scrollableElementIds';
import { daysOfWeek } from 'restaurant-v3/src/constants/tmpLocalization';
import { Logo } from 'restaurant-v3/src/components/Logo';
import { Map } from 'restaurant-v3/src/components/Map';
import IconNavNext from 'restaurant-v3/src/icons/nav-next.svg';
import QerkoLogoFull from 'restaurant-v3/src/icons/qerko-logo-full.svg';

import type { DomainWithLimitedSymbols } from 'src/shared/types';
import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';

type RestaurantInfo = Extract<APIv1RestaurantWeb['ENDPOINT']['GET:/restaurant-info']['result']['result'], {success: true}>['payload'];

type RestaurantAddress = RestaurantInfo['restaurant']['address'];

const formatRestaurantAddress = ({ street, descriptiveNumber, zipCode, city }: RestaurantAddress) => NonEmptyStringSchema.parse(`${street} ${descriptiveNumber}, ${zipCode} ${city}`);

const RestaurantAddress = (props: RestaurantAddress) => {
    return (
        <Box>
            {formatRestaurantAddress(props)}
        </Box>
    );
};

type RestaurantOpeningHoursProps = {
    restaurantOpeningHours: RestaurantInfo['restaurantOpeningHours'];
};

const MAP_ZOOM = 16;

const RestaurantOpeningHours: React.FC<RestaurantOpeningHoursProps> = ({ restaurantOpeningHours }) => {
    const now = (new Date()).getDay();
    const shouldDisplayOpeningHours = restaurantOpeningHours.some(({ open }) => open);
    if (!shouldDisplayOpeningHours) {
        return null;
    }
    // Maps Monday as 1st item
    const sortedOpeningHours = new Array(7).fill(null).map((_, index) => restaurantOpeningHours[(index + 1) % 7]);
    return (
        <Table
            sx={{
                minWidth: '15rem',
            }}
        >
            <TableBody
                sx={{
                    fontSize: '14px',
                }}
            >
                {sortedOpeningHours.map((openingHoursInDay) => (
                    <TableRow key={openingHoursInDay.day}>
                        <TableCell
                            sx={{
                                borderBottom: '0',
                                color: 'white.main',
                                padding: 0,
                                fontWeight: now === openingHoursInDay.day ? 'bold' : 'normal',
                            }}
                        >
                            <Typography
                                variant="body1"
                            >
                                {daysOfWeek[openingHoursInDay.day]}
                            </Typography>
                        </TableCell>
                        <TableCell
                            align="right"
                            sx={{
                                borderBottom: '0',
                                color: 'white.main',
                                padding: 0,
                                fontWeight: now === openingHoursInDay.day ? 'bold' : 'normal',
                            }}
                        >
                            {(function () {
                                switch (openingHoursInDay.open) {
                                    case true:
                                        return (
                                            <Typography
                                                variant="body1"
                                            >
                                                {`${formatTimeStringHourMinute(openingHoursInDay.openFrom)}`}&nbsp;-&nbsp;{`${formatTimeStringHourMinute(openingHoursInDay.openTo)}`}
                                            </Typography>
                                        );
                                    case false:
                                        return (
                                            // TODO - Localization here
                                            <Typography
                                                variant="body1"
                                            >
                                                Zavřeno
                                            </Typography>
                                        );
                                }
                            })()}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

type Props = {
    mapCoordinates: RestaurantInfo['mapCoordinates'];
    restaurant: Pick<RestaurantInfo['restaurant'], 'address' | 'name'>;
    restaurantDomain: DomainWithLimitedSymbols;
    restaurantLogo: RestaurantInfo['restaurantLogo'];
    restaurantOpeningHours: RestaurantInfo['restaurantOpeningHours'];
    // TBA - social media links - waiting for backend
    socialMediaLinks?: undefined;
};

const WebsiteByQerko: React.FC = () => {
    return (
        <MuiLink
            href="https://www.qerko.com"
            maxWidth="xl"
            sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    alignItems: 'center',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'neutral200.main',
                    borderRadius: 2.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    paddingY: 3,
                    paddingX: 3.5,
                    // TODO - fix responsive layout on this
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                    [theme.breakpoints.up('md')]: {
                        minWidth: '352px',
                    },
                })}
            >
                <Image
                    alt="Qerko Logo"
                    src={QerkoLogoFull}
                />
                <Box
                    sx={{
                        fontSize: 'body1',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold' }}>
                        WEBSITE BY QERKO
                    </Typography>
                    <Image
                        alt="Nav Next"
                        src={IconNavNext}
                    />
                </Box>
            </Box>
        </MuiLink>
    );
};

export const Footer: React.FC<Props> = ({ mapCoordinates, restaurant, restaurantDomain, restaurantLogo, restaurantOpeningHours }) => {
    return (
        <Box
            component="footer"
            maxWidth="xl"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mb: 2,
            }}
        >
            <Box
                id={footerId}
                sx={{
                    color: 'white.main',
                    bgcolor: 'neutral900.main',
                    borderRadius: 5,
                    marginTop: 1,
                    padding: 3.5,
                }}
            >
                <Box
                    maxWidth="xl"
                    sx={(theme) => ({
                        [theme.breakpoints.up('md')]: {
                            display: 'flex',
                            justifyContent: 'space-between',
                        },
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3.5,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        },
                    })}
                >
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3.75,
                            justifyContent: 'space-between',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            },
                        })}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2.5,
                            }}
                        >
                            <Logo
                                imageUrl={restaurantLogo}
                                // TODO - consider adding story here as well xD
                                // isPulsating
                                size={cssPxValues.footer.logoSize}
                                variant="rounded"
                            />
                            <MuiLink
                                href={getHref('/', restaurantDomain)}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {restaurant.name}
                                </Typography>
                            </MuiLink>
                        </Box>
                        <MuiLink
                            href={mapCoordinates.href}
                        >
                            <RestaurantAddress
                                {...restaurant.address}
                            />
                        </MuiLink>
                        <RestaurantOpeningHours
                            restaurantOpeningHours={restaurantOpeningHours}
                        />
                    </Box>
                    <Map
                        latitude={mapCoordinates.latitude}
                        longitude={mapCoordinates.longitude}
                        restaurantAddressString={formatRestaurantAddress(restaurant.address)}
                        zoom={MAP_ZOOM}
                    />
                </Box>
            </Box>
            <Box
                maxWidth="xl"
                sx={{
                    lineHeight: '20px',
                    width: '100%',
                    color: 'neutral500.main',
                    fontSize: '0.875rem',
                }}
            >
                <Box
                    sx={(theme) => ({
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        [theme.breakpoints.up('md')]: {
                            gap: 2.25,
                        },
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            gap: 1.75,
                        },
                    })}
                >
                    <Box
                        sx={(theme) => ({
                            [theme.breakpoints.up('md')]: {
                                flexGrow: 1,
                            },
                        })}
                    >
                        <Typography
                            variant="body2"
                        >
                            Qerko s.r.o.&nbsp;&nbsp;
                            <Box
                                component="span"
                                style={{ color: 'neutral300.main' }}
                            >
                                |
                            </Box>&nbsp;&nbsp;
                            Všechna práva vyhrazena&nbsp;&nbsp;
                            <Box
                                component="span"
                                style={{ color: 'neutral300.main' }}
                            >
                                |
                            </Box>&nbsp;&nbsp;
                            verze: {process.env.NEXT_PUBLIC_APP_VERSION?.substring(0, 12) || 'unknown'}
                        </Typography>
                    </Box>
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            [theme.breakpoints.up('md')]: {
                                gap: 2.25,
                            },
                            [theme.breakpoints.down('md')]: {
                                gap: 1.75,
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            },
                            alignItems: 'center',

                        })}
                    >
                        <MuiLink href="https://docs.google.com/document/d/e/2PACX-1vR0-oJfVNGIHd9Aq_-F-QdHNloxUIhx6IJbI5DUmVLeGiyVAGJyO3aBQGN2c5CnzG9LrBsxM5gxVWIc/pub">
                            <Typography
                                variant="body2"
                            >
                                Zásady používání souborů cookie
                            </Typography>
                        </MuiLink>
                        <MuiLink href="https://www.qerko.com/legal/obecne-podminky-sluzby-qerko">
                            <Typography
                                variant="body2"
                            >
                                Podmínky použití
                            </Typography>
                        </MuiLink>
                    </Box>
                    <WebsiteByQerko />
                </Box>
            </Box>
        </Box>
    );
};
