'use client';

import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import { atomSetWithStorage } from './atomSetWithStorage';

import type { Uuid } from 'src/shared/types';

export const viewedStoriesAtom = atomSetWithStorage<Uuid>(
    'viewedStories',
    createJSONStorage(),
    {
        getOnInit: true,
    },
);

export const viewedStoriesModalAtom = atomWithStorage<boolean>(
    'viewedStoriesModal',
    false,
    // @ts-expect-error not supposed to run on server
    createJSONStorage(() => typeof window !== 'undefined' ? window.sessionStorage : undefined),
    {
        getOnInit: true,
    }
);
