import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/qerko/qerko/frontend/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/qerko/qerko/frontend/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/qerko/qerko/frontend/node_modules/@mui/material/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/qerko/qerko/frontend/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/builds/qerko/qerko/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/qerko/qerko/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/Layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMobile"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/Layout/HeaderMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryLogo"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/StoryLogo.tsx");
