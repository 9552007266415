import { useState } from 'react';

import type { Stories } from './types';

type Props = {
    stories: Stories;
    onStoryView: (story: Stories[number]) => void;
};

export const useStoriesOverlayState = ({ stories, onStoryView }: Props) => {
    const [activeStoryIndex, setActiveStoryIndex] = useState(0);

    const onNextStory = () => setActiveStoryIndex((prevActiveStoryIndex) => {
        if (prevActiveStoryIndex >= stories.length - 1) {
            return stories.length - 1;
        }
        onStoryView(stories[prevActiveStoryIndex + 1]);
        return prevActiveStoryIndex + 1;
    });
    const onPrevStory = () => setActiveStoryIndex((prevActiveStoryIndex) => {
        if (prevActiveStoryIndex <= 0) {
            return 0;
        }
        onStoryView(stories[prevActiveStoryIndex - 1]);
        return prevActiveStoryIndex - 1;
    });
    const onSelectStoryByIndex = (storyIndex: number) => setActiveStoryIndex(Math.min(stories.length - 1, Math.max(0, storyIndex)));
    return {
        onNextStory,
        onPrevStory,
        onSelectStoryByIndex,
        hasNextStory: activeStoryIndex < stories.length - 1,
        hasPrevStory: activeStoryIndex > 0,
        activeStoryIndex,
    };
};
