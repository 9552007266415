'use client';

import React from 'react';
import MuiLink from '@mui/material/Link';
import { getHref } from 'restaurant-v3/src/utils/navigation';
import { Box } from '@mui/system';
import { useAtomValue } from 'jotai';
import { viewedStoriesAtom, viewedStoriesModalAtom } from 'restaurant-v3/src/atoms/viewedStories';

import { Logo } from './Logo';
import { useStoriesOverlay } from './Stories';

import type { useRestaurantInfoQuery } from 'restaurant-v3/src/hooks/queries/useRestaurantInfoQuery';
import type { DomainWithLimitedSymbols, Uuid } from 'src/shared/types';

type LogoProps = Parameters<typeof Logo>[0];

type Stories = Extract<ReturnType<typeof useRestaurantInfoQuery>['data']['result'], {success: true}>['payload']['stories'];

type Props = {
    restaurantDomain: DomainWithLimitedSymbols;
    logoProps: Omit<LogoProps, 'outline'>;
    stories: Stories;
};

const resolveStoriesLogoOutline = (stories: Stories, seenStoryIds: Uuid[], seenStoryModal: boolean): LogoProps['outline'] => {
    if (stories.length === 0) {
        return undefined;
    }
    if (seenStoryModal || stories.every(({ id }) => seenStoryIds.includes(id))) {
        return 'grey';
    }
    return 'pulsating';
};

export const StoryLogo: React.FC<Props> = ({ stories, logoProps, restaurantDomain }) => {
    const viewedStories = useAtomValue(viewedStoriesAtom);
    const viewedStoriesModal = useAtomValue(viewedStoriesModalAtom);
    const hasRestaurantStories = stories.length > 0;
    const {
        enabled,
        storiesOverlay,
        openStories,
    } = useStoriesOverlay({
        stories,
        enabled: hasRestaurantStories,
    });
    const outline = resolveStoriesLogoOutline(stories, viewedStories, viewedStoriesModal);
    const logo = (
        <Logo
            {...logoProps}
            outline={outline}
        />
    );
    if (!enabled) {
        return (
            <MuiLink
                href={getHref('/', restaurantDomain)}
            >
                {logo}
            </MuiLink>
        );
    }
    return (
        <>
            {storiesOverlay}
            <Box
                onClick={openStories}
                sx={{
                    cursor: 'pointer',
                }}
            >
                {logo}
            </Box>
        </>
    );
};

