import React from 'react';
import Box from '@mui/material/Box';
import { s3ImageLoader } from 'restaurant-v3/src/utils/s3ImageLoader';

import type { HttpUrl, PositiveInteger } from 'src/shared/types';

type Props = {
    variant: 'rounded' | 'circular';
    imageUrl: HttpUrl | null;
    size: PositiveInteger;
    outline?: 'pulsating' | 'grey';
};

export const Logo: React.FC<Props> = ({ imageUrl, size, outline, variant }) => {
    if (imageUrl === null) {
        return null;
    }

    return (
        <Box
            sx={{
                backgroundImage: `url('${s3ImageLoader({ src: imageUrl, width: size })}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: `${size}px`,
                height: `${size}px`,
                ...(variant === 'rounded' ? {
                    borderRadius: 2,
                } : {}),
                ...(variant === 'circular' ? {
                    borderRadius: '50%',
                } : {}),
                ...(outline === 'grey' ? {
                    outlineColor: 'neutral300.main',
                    outlineStyle: 'solid',
                    outlineWidth: '2px',
                    outlineOffset: 4,
                } : {}),
                ...(outline === 'pulsating' ? {
                    '@keyframes logoPulse': {
                        '0%, 60%': {
                            outlineColor: 'transparent',
                            transform: 'scale(1)',
                        },
                        '20%, 40%': {
                            outlineColor: 'salad500.main',
                            outlineStyle: 'solid',
                            outlineWidth: '3.2px',
                            outlineOffset: 0,
                            transform: 'scale(0.95)',
                        },
                    },
                    animation: 'logoPulse 3s cubic-bezier(0, 0, 0.2, 1) infinite',
                } : {}),
            }}
        />
    );
};
