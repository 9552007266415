import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    length: number;
    onDotClick: (index: number) => void;
    activeItemIndex: number;
    style?: {
        size: number;
        gap: number;
        zIndex?: number;
    };
};

const defaultStyle: Props['style'] = {
    size: 12,
    gap: 1.5,
};

export const Dots: React.FC<Props> = ({ length, onDotClick, activeItemIndex, style }: Props) => (
    <Box
        sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: style?.gap ?? defaultStyle.gap,
        }}
    >
        {Array(length).fill(null).map((_, index) => (
            <Box
                key={index}
                onClick={() => onDotClick(index)}
                sx={{
                    cursor: 'pointer',
                    width: style?.size ?? defaultStyle.size,
                    height: style?.size ?? defaultStyle.size,
                    borderRadius: '50%',
                    backgroundColor: index === activeItemIndex ? 'neutral900.main' : 'neutral400.main',
                    zIndex: style?.zIndex,
                }}
            />
        ))}
    </Box>
);
