'use client';

import React, { useCallback, useMemo, useState } from 'react';
import useMediaQuery from '@mui/system/useMediaQuery';
import { useAtom } from 'jotai';
import { viewedStoriesAtom, viewedStoriesModalAtom } from 'restaurant-v3/src/atoms/viewedStories';
import { useRestaurantWebMutation } from 'restaurant-v3/src/hooks/useApi';
import { useRestaurantDomain } from 'restaurant-v3/src/hooks/queries/useRestaurantDomain';

import { StoriesBrowser } from './StoriesBrowser';
import { StoriesMobile } from './StoriesMobile';

import type { Stories } from './types';
import type { Theme } from '@mui/material';

export const useStoriesOverlay = ({
    stories,
    enabled,
}: {
    enabled?: boolean;
    stories: Stories;
}) => {
    const [storiesOpen, setStoriesOpen] = useState(false);
    const openStories = () => setStoriesOpen(true);
    const closeStories = () => setStoriesOpen(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const {
        mutateAsync,
    } = useRestaurantWebMutation('POST:/record-anonymous-impression');
    const [,setViewedStories] = useAtom(viewedStoriesAtom);
    const [, setViewedStoriesModal] = useAtom(viewedStoriesModalAtom);
    const restaurantDomain = useRestaurantDomain();
    const recordAnonymousStoryImpression = useCallback((story: Stories[number]) => setViewedStories((prevViewedStories) => {
        if (prevViewedStories.has(story.id)) {
            return prevViewedStories;
        }
        return mutateAsync({
            data: {
                storyId: story.id,
            },
            query: {
                restaurantDomain,
            },
        }).then(() => {
            return prevViewedStories.add(story.id);
        }).catch(() => {
            return prevViewedStories;
        });
    }), [mutateAsync, restaurantDomain, setViewedStories]);
    const storiesOverlay = useMemo(() => {
        if (!enabled) {
            return null;
        }
        if (!isMobile) {
            return (
                <StoriesBrowser
                    onClose={() => setStoriesOpen(false)}
                    onStoriesOpen={() => setViewedStoriesModal(true)}
                    onStoryView={recordAnonymousStoryImpression}
                    open={storiesOpen}
                    stories={stories}
                />
            );
        }
        if (storiesOpen) {
            return (
                <StoriesMobile
                    onClose={() => setStoriesOpen(false)}
                    onStoriesOpen={() => setViewedStoriesModal(true)}
                    onStoryView={recordAnonymousStoryImpression}
                    stories={stories}
                />
            );
        }
        return null;
    }, [enabled, isMobile, storiesOpen, stories, setViewedStoriesModal, recordAnonymousStoryImpression]);
    return {
        enabled: Boolean(enabled),
        storiesOverlay,
        openStories,
        closeStories,
        isOpen: storiesOpen,
    };
};
