import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import Image from 'next/image';
import IconClose from 'restaurant-v3/src/icons/close.svg';
import SlideshowArrowLeft from 'restaurant-v3/src/icons/arrow-left.svg';
import SlideshowArrowRight from 'restaurant-v3/src/icons/arrow-right.svg';

import { useStoriesOverlayState } from './useStoriesOverlayState';
import { StoriesSlideshow } from './StoriesSlideshow';

import type { StoriesProps } from './types';

type Props = Omit<StoriesProps, 'open'>;

export const StoriesMobile: React.FC<Props> = ({ onClose, onStoryView, onStoriesOpen, stories }) => {
    const {
        activeStoryIndex,
        hasNextStory,
        hasPrevStory,
        onNextStory,
        onPrevStory,
    } = useStoriesOverlayState({
        onStoryView,
        stories,
    });
    useEffect(() => {
        onStoryView(stories[activeStoryIndex]);
        onStoriesOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box
            sx={{
                display: 'flex',
                position: 'fixed',
                zIndex: 1010,
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                backgroundColor: 'neutral900.main',
            }}
        >
            <Box
                onClick={onClose}
                sx={{
                    backgroundColor: 'neutral200.main',
                    cursor: 'pointer',
                    position: 'absolute',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    opacity: 0.5,
                    right: 20,
                    paddingX: 0.5,
                    paddingTop: 0.5,
                    top: 20,
                    zIndex: 1040,
                }}
            >
                <Image
                    alt="navicon"
                    src={IconClose}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        width: '100%',
                        px: 3,
                    }}
                >
                    {/* Left arrow click - go to previous story */}
                    <button
                        disabled={!hasPrevStory}
                        onClick={onPrevStory}
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            fill: '#000000',
                            borderRadius: '50%',
                            transitionProperty: 'opacity',
                            transitionDuration: '300ms',
                            transitionTimingFunction: 'ease-in-out',
                            opacity: hasPrevStory ? 1 : 0,
                            zIndex: 1030,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: 'neutral200.main',
                                borderRadius: '50%',
                                opacity: 0.8,
                                padding: 2,
                            }}
                        >
                            <Image
                                alt="Right arrow"
                                src={SlideshowArrowLeft}
                            />
                        </Box>
                    </button>
                    {/* Right arrow click - go to next story */}
                    <button
                        disabled={!hasNextStory}
                        onClick={onNextStory}
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            fill: '#000000',
                            borderRadius: '50%',
                            transitionProperty: 'opacity',
                            transitionDuration: '300ms',
                            transitionTimingFunction: 'ease-in-out',
                            opacity: hasNextStory ? 1 : 0,
                            zIndex: 1030,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: 'neutral200.main',
                                borderRadius: '50%',
                                opacity: 0.8,
                                padding: 2,
                            }}
                        >
                            <Image
                                alt="Right arrow"
                                src={SlideshowArrowRight}
                            />
                        </Box>
                    </button>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <StoriesSlideshow
                    currentStoryIndex={activeStoryIndex}
                    fullScreen
                    stories={stories}
                />
            </Box>
        </Box>
    );
};
