import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import type { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';

type AtomWithStorageOptions = Parameters<typeof atomWithStorage>[3];

export const defaultSerializer = <Value>(set: Set<Value>) => Array.from(set);
export const defaultDeserializer = <Value>(serializedValue: Value[]) => new Set<Value>(serializedValue);

export const atomSetWithStorage = <Value, SerializedValue = Value[]>(
    key: string,
    storage: SyncStorage<SerializedValue> = createJSONStorage(),
    options: AtomWithStorageOptions & {
        serializer?: (deserializedValue: Set<Value>) => SerializedValue;
        deserializer?: (serializedValue: SerializedValue) => Set<Value>;
    } = {
        // @ts-expect-error
        serializer: defaultSerializer,
        // @ts-expect-error
        deserializer: defaultDeserializer,
    },
) => {
    const serializer = options.serializer ?? defaultSerializer as NonNullable<typeof options.serializer>;
    const deserializer = options.deserializer ?? defaultDeserializer as NonNullable<typeof options.deserializer>;
    const innerAtomWithStorage = atomWithStorage<SerializedValue>(
        key,
        serializer(new Set<Value>()),
        storage,
        {
            getOnInit: options.getOnInit,
        },
    );
    const resultAtom = atom(
        (get) => get(innerAtomWithStorage),
        (get, set, update: (Set<Value> | ((prev: Set<Value>) => Set<Value> | Promise<Set<Value>>))) => {
            const rawNextValue =
                typeof update === 'function'
                    ? (
                        update as (
                            prev: Set<Value>,
                        ) => Set<Value> | Promise<Set<Value>>
                    )(deserializer(get(innerAtomWithStorage)))
                    : update;
            if (rawNextValue instanceof Promise) {
                return rawNextValue.then((resolvedValue) => {
                    const nextValue = serializer(resolvedValue);
                    set(innerAtomWithStorage, nextValue);
                    return storage.setItem(key, nextValue);
                });
            }
            const nextValue = serializer(rawNextValue);
            set(innerAtomWithStorage, nextValue);
            return storage.setItem(key, nextValue);
        },
    );
    return resultAtom;
};

