import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import IconClose from 'restaurant-v3/src/icons/close.svg';
import SlideshowArrowLeft from 'restaurant-v3/src/icons/arrow-left.svg';
import SlideshowArrowRight from 'restaurant-v3/src/icons/arrow-right.svg';
import { Dots } from 'restaurant-v3/src/components/Dots';

import { StoriesSlideshow } from './StoriesSlideshow';
import { useStoriesOverlayState } from './useStoriesOverlayState';

import type { StoriesProps } from './types';

export const StoriesBrowser: React.FC<StoriesProps> = ({ open, onClose, onStoryView, onStoriesOpen, stories }) => {
    const {
        activeStoryIndex,
        hasNextStory,
        hasPrevStory,
        onNextStory,
        onPrevStory,
        onSelectStoryByIndex,
    } = useStoriesOverlayState({
        stories,
        onStoryView,
    });
    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowRight') {
            onNextStory();
        }
        if (event.key === 'ArrowLeft') {
            onPrevStory();
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (open) {
            onStoryView(stories[activeStoryIndex]);
            onStoriesOpen();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Modal
            onClose={onClose}
            open={open}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'white.main',
                    borderRadius: 5,
                    p: 5,
                    minWidth: '500px',
                    outline: 'none',
                    position: 'relative',
                }}
            >

                <Box
                    onClick={onClose}
                    sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        borderRadius: 1,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'neutral200.main',
                        right: 20,
                        paddingX: 0.5,
                        paddingTop: 0.5,
                    }}
                >
                    <Image
                        alt="navicon"
                        src={IconClose}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 6,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h3"
                    >
                        Novinky a události
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContents: 'center',
                            gap: 3,
                        }}
                    >
                        <button
                            disabled={!hasPrevStory}
                            onClick={onPrevStory}
                            style={{
                                all: 'unset',
                                cursor: 'pointer',
                                fill: '#000000',
                                borderRadius: '50%',
                                visibility: hasPrevStory ? 'visible' : 'hidden',
                                zIndex: 30,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    bgcolor: 'rgba(255,255,255,0.2)',
                                    borderRadius: '50%',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'neutral200.main',
                                    padding: 2,
                                }}
                            >
                                <Image
                                    alt="Left arrow"
                                    src={SlideshowArrowLeft}
                                />
                            </Box>
                        </button>
                        <StoriesSlideshow
                            currentStoryIndex={activeStoryIndex}
                            stories={stories}
                        />
                        <button
                            disabled={!hasNextStory}
                            onClick={onNextStory}
                            style={{
                                all: 'unset',
                                cursor: 'pointer',
                                fill: '#000000',
                                borderRadius: '50%',
                                visibility: hasNextStory ? 'visible' : 'hidden',
                                zIndex: 30,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    bgcolor: 'rgba(255,255,255,0.2)',
                                    borderRadius: '50%',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'neutral200.main',
                                    padding: 2,
                                }}
                            >
                                <Image
                                    alt="Right arrow"
                                    src={SlideshowArrowRight}
                                />
                            </Box>
                        </button>
                    </Box>
                    <Dots
                        activeItemIndex={activeStoryIndex}
                        length={stories.length}
                        onDotClick={onSelectStoryByIndex}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
