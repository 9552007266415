import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import type Leaflet from 'leaflet';
import type { OpenStreetMapLeafletProps } from './OpenStreetMapLeaflet';
import type { NonEmptyString } from 'src/shared/types';

// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-no-ssr
// Leaflet dependant on `window` object
const OpenStreetMapLeafletCSR = dynamic(async () => (await (import('./OpenStreetMapLeaflet'))).OpenStreetMapLeaflet, {
    ssr: false,
});

const LeafletWrapper: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    // Not imports with 'next/dynamic', nor imports based on `typeof window !== undefined` worked
    // Made workaround using React.useState
    const [leafletLib, setLeafletLib] = useState<typeof Leaflet | undefined>(undefined);
    useEffect(() => {
        import('leaflet').then((leaflet) => {
            setLeafletLib(leaflet);
        });
    }, []);
    return (
        <Box
            sx={{
                width: '100%',
                height: 320,
            }}
        >
            {!leafletLib && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
            {leafletLib && (
                children
            )}
        </Box>
    );
};

export const Map: React.FC<OpenStreetMapLeafletProps & {
    restaurantAddressString: NonEmptyString;
}> = ({
    latitude,
    longitude,
    restaurantAddressString,
    zoom,
}) => {
    return (
        <Box
            sx={(theme) => ({
                opacity: 1,
                height: 320,
                [theme.breakpoints.up('md')]: {
                    width: 320,
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
                position: 'relative',
            })}
        >
            <MuiLink
                href={`https://en.mapy.cz/zakladni?x=${longitude}&y=${latitude}&z=${zoom}&q=${encodeURIComponent(restaurantAddressString)}`}
            >
                <LeafletWrapper>
                    <OpenStreetMapLeafletCSR
                        latitude={latitude}
                        longitude={longitude}
                        zoom={zoom}
                    />
                </LeafletWrapper>
            </MuiLink>
        </Box>
    );
};
