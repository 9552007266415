import Image from 'next/image';
import React from 'react';
import Box from '@mui/material/Box';
import { s3ImageLoader } from 'restaurant-v3/src/utils/s3ImageLoader';
import Typography from '@mui/material/Typography';

import { STORIES_BROWSER_PRIMARY_IMAGE_WIDTH, STORIES_BROWSER_PRIMARY_IMAGE_HEIGHT, STORIES_TRANSITION_DURATION_MS } from './consts';

import type { Stories } from './types';

type Props = {
    currentStoryIndex: number;
    stories: Stories;
    fullScreen?: boolean;
};

export const StoriesSlideshow: React.FC<Props> = ({ fullScreen, currentStoryIndex, stories }) => (
    <Box
        sx={{
            position: 'relative',
            minHeight: STORIES_BROWSER_PRIMARY_IMAGE_HEIGHT,
            minWidth: STORIES_BROWSER_PRIMARY_IMAGE_WIDTH,
            ...(fullScreen ? {
                height: '100%',
                width: '100%',
            } : {}),
        }}
    >
        {stories.map((story, index) => {
            return (
                <Box
                    key={story.id}
                    sx={{
                        height: '100%',
                        position: 'absolute',
                        transitionDuration: `${STORIES_TRANSITION_DURATION_MS / 1000}s`,
                        transitionProperty: 'opacity, transform',
                        transitionTimingFunction: 'ease-in-out',
                        width: '100%',
                        ...((function () {
                            if (index === currentStoryIndex) {
                                return {
                                    opacity: 1,
                                };
                            }
                            if (index < currentStoryIndex) {
                                return {
                                    opacity: 0,
                                    transform: 'translate(-360px,0)',
                                };
                            }
                            return {
                                opacity: 0,
                                transform: 'translate(360px,0)',
                            };
                        })()),
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            background: 'linear-gradient(to top, transparent 30%, black 100%)',
                            width: '100%',
                            zIndex: 1000,
                            position: 'absolute',
                            ...(fullScreen ? {} : {
                                borderRadius: 5,
                            }),
                        }}
                    />
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                    }}
                    >
                        {story.backgroundImageUrl && !fullScreen && (
                            <Image
                                alt={story.type === 'message' && story.title !== null ? story.title : 'Untitled story'}
                                height={STORIES_BROWSER_PRIMARY_IMAGE_HEIGHT}
                                loader={s3ImageLoader}
                                src={story.backgroundImageUrl}
                                style={{
                                    borderRadius: 20,
                                    objectFit: 'cover',
                                    position: 'absolute',
                                }}
                                width={STORIES_BROWSER_PRIMARY_IMAGE_WIDTH}
                            />
                        )}
                        {story.backgroundImageUrl && fullScreen && (
                            <Image
                                alt={story.type === 'message' && story.title !== null ? story.title : 'Untitled story'}
                                height={0}
                                loader={s3ImageLoader}
                                sizes="100vw"
                                src={story.backgroundImageUrl}
                                style={{
                                    objectFit: 'cover',
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'absolute',
                                }}
                                width={0}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                height: '100%',
                                justifyContent: 'flex-start',
                                position: 'absolute',
                                p: 3,
                                width: '100%',
                                zIndex: 1020,

                            }}
                        >
                            {story.type === 'message' && (
                                <>
                                    <Typography
                                        sx={{
                                            my: 1,
                                            color: '#ffffff',
                                            fontSize: '1.6rem',
                                            textShadow: '0 0 10px #000000, 0 0 15px #000000',
                                            fontWeight: 'bold',
                                        }}
                                        variant="h4"
                                    >
                                        {story.title}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            color: '#ffffff',
                                            fontSize: '1rem',
                                            textShadow: '0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000',
                                            fontWeight: 'regular',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                        variant="h6"
                                    >
                                        {story.content}
                                    </Typography>
                                </>
                            )}

                            {story.contentImageUrl && (
                                <Box sx={{
                                    marginTop: '16px',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                                >
                                    <Image
                                        alt="Content"
                                        height={0}
                                        sizes="100vw 50vw"
                                        src={story.contentImageUrl}
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '5%',
                                        }}
                                        width={0}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            );
        })}
    </Box>
);
